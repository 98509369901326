import React from 'react';
import { useCSS } from '@insuma/mpp-ui/hooks';

import './customer-form-subtitle.scss';

interface ICustomerFormSubtitleProps {
  children: React.ReactNode;
  standalone?: boolean;
}
export const CustomerFormSubtitle = ({ children, standalone = false }: ICustomerFormSubtitleProps) => {
  const css = useCSS('customer-form-subtitle');
  return <h4 className={css({ standalone })}>{children}</h4>;
};
