import { useFormContext } from 'react-hook-form';
import { IGroupingRulesFormData } from 'core/model/interfaces/grouping-rules';
import { InputValueForm } from 'shared/components/forms';

import './grouping-rules-name.scss';

const GroupingRulesName = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<IGroupingRulesFormData>();

  return (
    <div className="row-container">
      <h4 className="row-container__subtitle">1. Datos de la agrupación</h4>
      <div className="grouping-name">
        <InputValueForm
          label="Nombre de la agrupación"
          register={register}
          name="deliveryGroupingRuleName"
          isInvalid={!!errors.deliveryGroupingRuleName}
          helperText={errors.deliveryGroupingRuleName?.message}
        />
      </div>
    </div>
  );
};

export default GroupingRulesName;
