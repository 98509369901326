import { Icon } from '@insuma/mpp-ui/components/icon';
import LogoMPPSvg from 'assets/logom++.svg';

import './header.scss';

export interface IHeaderProps {
  userFullName: string;
  buttonClicked: boolean;
  onButtonClick: () => void;
}

export const Header = ({ userFullName, buttonClicked, onButtonClick }: IHeaderProps) => (
  <div className="header">
    <div className="header__start">
      <button type="button" className="header__menu-button" onClick={onButtonClick}>
        {buttonClicked ? <Icon name="x" weight="bold" /> : <Icon name="hamburger" weight="bold" />}
      </button>
      <div>
        <img src={LogoMPPSvg} alt="M++" />
      </div>
    </div>
    <div className="header__end">
      <div className="header__user">
        <p className="header__user-item">Hola, {userFullName}</p>
      </div>
    </div>
  </div>
);
