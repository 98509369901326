import { Button } from '@insuma/mpp-ui/components/button';
import type { IColumnProps } from '@insuma/mpp-ui/components/table';
import type { IDisplayExport } from 'core/model/interfaces/exports';

export const exportsColumns = (onDownload: (fileUrl: string) => void): Array<IColumnProps<IDisplayExport>> => [
  { header: 'Nombre', accessor: 'name' },
  { header: 'Fecha y hora', accessor: 'createdAt' },
  { header: 'Estado', accessor: 'status' },
  {
    header: '',
    accessor: 'events',
    render: row => (
      <Button
        size="sm"
        hasIcon="left"
        iconName="download"
        variant="tertiary"
        disabled={!row.isDownloadEnabled}
        onClick={() => onDownload(row.fileUrl)}
      >
        Descargar
      </Button>
    ),
  },
];
