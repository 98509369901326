import { get, useFormContext } from 'react-hook-form';
import { FormGroup, Label } from '@insuma/mpp-ui/components/form';
import { HelperText } from '@insuma/mpp-ui/components/helper-text';
import { IHierarchyTreeCategoryCreateForm } from 'core/model/interfaces/navigation/hierarchy.interface';
import { DragAndDropImageUploaderForm, InputValueForm } from 'shared/components/forms';

export const HierarchyCategoryMainFields = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IHierarchyTreeCategoryCreateForm>();
  return (
    <>
      <InputValueForm
        label="Nombre de la categoría"
        name="name"
        register={register}
        isInvalid={!!errors.name}
        helperText={errors.name?.message}
      />
      <FormGroup>
        <Label>Icono</Label>
        <DragAndDropImageUploaderForm name="image" maxSize={500} />
        {errors.image && <HelperText isInvalid>{get(errors, 'image.blob.message')}</HelperText>}
      </FormGroup>
    </>
  );
};
