import { useEffect, useState } from 'react';
import { Img } from '@insuma/mpp-ui/components/img';

interface IImage {
  image: string | null;
  defaultImgSrc: string;
  name: string;
  className?: string;
}

export const Image = ({ image, defaultImgSrc, name, className }: IImage) => {
  const [imgSrc, setImgSrc] = useState(image);

  useEffect(() => {
    setImgSrc(image);
  }, [image]);

  return (
    <Img
      className={className}
      src={imgSrc || defaultImgSrc}
      alt={name || 'product-cover'}
      role="img"
      onError={() => setImgSrc(defaultImgSrc)}
    />
  );
};
