import { FieldErrors } from 'react-hook-form';
import { Dropdown } from '@alicorpdigital/dali-components-react';
import { TimePickerInput } from '@insuma/mpp-ui/components/form';
import type { TEnglishDay } from 'core/constants/logistics.constants';
import {
  IDropdownDeliveryOptions,
  TDeliveryByShoppingDayMap,
  TDeliveryConditionFormData,
} from 'core/model/interfaces/delivery-condition';
import { Table, TBody, TD, TH, THead, TR } from 'shared/components/table';
import { mapSpanishDayToEnglishDay } from '../../delivery-condition.utils';

import './delivery-days-table.scss';

interface IDeliveryDaysTableProps {
  deliveryDays: Array<TEnglishDay>;
  deliveryOptions: Array<IDropdownDeliveryOptions>;
  onChange: (value: TDeliveryByShoppingDayMap) => void;
  value: TDeliveryByShoppingDayMap;
  errors: FieldErrors<TDeliveryConditionFormData>;
}

// TODO: Replace for MPPUI Table
export const DeliveryDaysTable = ({
  deliveryDays,
  onChange,
  value,
  errors,
  deliveryOptions,
}: IDeliveryDaysTableProps) => {
  const onChangeTime = (day: TEnglishDay, cutoffTime: string) => {
    const mapCopy = structuredClone(value);
    mapCopy[day].cutoffTime = cutoffTime;
    onChange(mapCopy);
  };

  const onChangeAfterOption = (day: TEnglishDay, option: string) => {
    const mapCopy = structuredClone(value);
    mapCopy[day].additionalDaysAfterCutoffTime = Number(option);
    onChange(mapCopy);
  };

  const onChangeBeforeOption = (day: TEnglishDay, option: string) => {
    const mapCopy = structuredClone(value);
    mapCopy[day].additionalDaysBeforeCutoffTime = Number(option);
    onChange(mapCopy);
  };

  return (
    <Table className="delivery-days-table">
      <THead>
        <TR>
          <th className="delivery-days-table__th">Día de compra</th>
          <th className="delivery-days-table__th">Hora de corte</th>
          <th className="delivery-days-table__th pad-right">Entrega (Antes hora de corte)</th>
          <th className="delivery-days-table__th pad-left">Entrega (Después hora de corte)</th>
        </TR>
      </THead>
      <TBody>
        {deliveryOptions.map(item => {
          const dayCode = mapSpanishDayToEnglishDay(item.day);
          const selectedBeforeOption =
            item.beforeOptions.find(option => option.id === value[dayCode].additionalDaysBeforeCutoffTime.toString()) ||
            null;
          const selectedAfterOption =
            item.afterOptions.find(option => option.id === value[dayCode].additionalDaysAfterCutoffTime.toString()) ||
            null;
          return (
            <TR key={item.day}>
              <TH>{item.day}</TH>
              <TD>
                <div className="delivery-days-table__time-picker">
                  <TimePickerInput
                    isDisabled={deliveryDays.length === 0}
                    size="md"
                    // TODO: Retirar condicion de error
                    className={errors.purchaseDayDeliveryConfigurations?.[dayCode] ? 'time-picker-error' : ''}
                    time={value[dayCode].cutoffTime}
                    setTime={(val: string) => onChangeTime(dayCode, val)}
                    withoutFormControl
                  />
                </div>
              </TD>
              <TD className="delivery-days-table__pad-right">
                <Dropdown
                  size="md"
                  items={item.beforeOptions}
                  selectedItem={selectedBeforeOption}
                  handleSelectedItemChange={({ selectedItem }) => onChangeBeforeOption(dayCode, selectedItem?.id || '')}
                  isDisabled={deliveryDays.length === 0}
                />
              </TD>
              <TD className="delivery-days-table__pad-left">
                <Dropdown
                  size="md"
                  items={item.afterOptions}
                  selectedItem={selectedAfterOption}
                  handleSelectedItemChange={({ selectedItem }) => onChangeAfterOption(dayCode, selectedItem?.id || '')}
                  isDisabled={deliveryDays.length === 0}
                />
              </TD>
            </TR>
          );
        })}
      </TBody>
    </Table>
  );
};
