import { useFormContext, useWatch } from 'react-hook-form';
import { FormControl, FormGroup, Label } from '@insuma/mpp-ui/components/form';
import { HelperText } from '@insuma/mpp-ui/components/helper-text';
import { useCategories, useDistributorsOptions, usePriceList, usePricesGroup } from 'core/hooks/master-entities';
import { AutocompleteForm } from 'shared/components/forms';

import './grouping-rules-conditions.scss';

const GroupingRulesConditions = () => {
  const {
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext();

  const selectedDexPrices = useWatch({
    name: 'conditions.externalPriceGroupIds',
    defaultValue: [],
  });

  const { pricesGroupList, loading: loadingPriceGroup } = usePricesGroup();
  const { priceList, loading: loadingPriceList } = usePriceList(selectedDexPrices);
  const { categoriesList, loading: loadingCategories } = useCategories();
  const { distributorsList, loading: loadingDistributors } = useDistributorsOptions();

  return (
    <div className="row-container">
      <h4 className="row-container__subtitle">2. Condiciones</h4>
      <div className="conditions">
        <FormGroup>
          <Label className="conditions__label">
            Definir uno o más parámetros acumulables que han de cumplir los productos para ser incluidos en un mismo
            paquete
          </Label>
        </FormGroup>

        <FormGroup>
          <Label>Por grupo de precios DEX</Label>
          <div className="conditions__autocomplete">
            <AutocompleteForm
              source={pricesGroupList}
              name="conditions.externalPriceGroupIds"
              isLoading={loadingPriceGroup}
              getOptionValue={option => option.priceGroupCode}
              getOptionLabel={option => option.priceGroupDescription}
              onChangeProp={() => {
                setValue('conditions.externalPriceListIds', []);
                trigger('conditions');
              }}
              isMulti
              withoutFormControl
            />
          </div>
        </FormGroup>

        <FormGroup>
          <Label>Por lista de precios DEX</Label>
          <div className="conditions__autocomplete">
            <AutocompleteForm
              source={priceList}
              name="conditions.externalPriceListIds"
              isLoading={loadingPriceList}
              getOptionValue={option => option.priceListCode}
              getOptionLabel={option => option.priceListDescription}
              onChangeProp={() => trigger('conditions')}
              isMulti
              withoutFormControl
            />
          </div>
        </FormGroup>

        <FormGroup>
          <Label>Por categoría</Label>
          <div className="conditions__autocomplete">
            <AutocompleteForm
              source={categoriesList}
              name="conditions.externalProductCategoryIds"
              isLoading={loadingCategories}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.description}
              onChangeProp={() => trigger('conditions')}
              isMulti
              withoutFormControl
            />
          </div>
        </FormGroup>

        <FormGroup>
          <Label>Por operador</Label>
          <div className="conditions__autocomplete">
            <AutocompleteForm
              source={distributorsList}
              name="conditions.externalDistributors"
              isLoading={loadingDistributors}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.name}
              onChangeProp={() => trigger('conditions')}
              isMulti
              withoutFormControl
            />
          </div>
        </FormGroup>

        <FormControl>{errors.conditions && <HelperText isInvalid>{errors.conditions.message}</HelperText>}</FormControl>
      </div>
    </div>
  );
};

export default GroupingRulesConditions;
