import type { ICustomerTaxData } from 'core/model/interfaces/customers';
import { CustomerFormLayout } from 'screens/customers/customer-form/components';
import { InputValueForm } from 'shared/components/forms';

interface ICustomerTaxesDataProps {
  taxData: ICustomerTaxData;
}
export const CustomerTaxesData = ({ taxData: { affectedByIGV, perceptionAgent } }: ICustomerTaxesDataProps) => (
  <CustomerFormLayout columns="two">
    <InputValueForm label="Afecto a IGV" name="Afecto a IGV" value={affectedByIGV} isDisabled />
    <InputValueForm label="Agente de Percepción" name="Agente de Percepción" value={perceptionAgent} isDisabled />
  </CustomerFormLayout>
);
