import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { FormGroup, Label } from '@insuma/mpp-ui/components/form';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { HIERARCHY_ASSOCIATION_TYPES } from 'core/constants/hierarchy.constants';
import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';
import type {
  IHierarchyTreeFamily,
  IHierarchyTreeSubcategory,
} from 'core/model/interfaces/navigation/hierarchy.interface';
import { AutocompleteForm, InputValueForm } from 'shared/components/forms';
import { TextareaForm } from 'shared/components/forms/textarea-form';
import { Modal } from 'shared/components/modal';
import { useHierarchyTreeFamilies } from '../../../hooks';
import { defaultValues } from './hierarchy-subcategory-modal-form.constants';
import { schema } from './hierarchy-subcategory-modal-form.schema';

import './hierarchy-subcategory-modal-form.scss';

export interface IHierarchySubcategoryModalFormProps {
  categoryName: string;
  onClose(): void;
  onSubmit(data: IHierarchyTreeSubcategory): void;
  selectedSubcategory?: IHierarchyTreeSubcategory;
  treeId: string;
  isButtonLoading: boolean;
}

export const HierarchySubcategoryModalForm = ({
  onClose,
  onSubmit,
  categoryName,
  selectedSubcategory,
  treeId,
  isButtonLoading,
}: IHierarchySubcategoryModalFormProps) => {
  const formMethods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    register,
  } = formMethods;
  const associationType = watch('associationType');
  const { familiesList, loading: loadingFamilies } = useHierarchyTreeFamilies({ treeId });

  const isOptionDisabled = (family: IHierarchyTreeFamily) => {
    if (!selectedSubcategory) return !family.available;
    const isSelected = Number(selectedSubcategory.families?.findIndex(item => item === family.id)) >= 0;
    return isSelected ? false : !family.available;
  };

  useEffect(() => {
    if (selectedSubcategory) reset(selectedSubcategory);
  }, [selectedSubcategory, reset]);

  return (
    <FormProvider {...formMethods}>
      <Modal>
        <div className="hierarchy-subcategory-modal-form">
          <div className="hierarchy-subcategory-modal-form__top-actions">
            <button
              className="hierarchy-subcategory-modal-form__close-icon"
              type="button"
              onClick={onClose}
              disabled={isButtonLoading}
            >
              <Icon size="lg" name="x" />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} aria-label="Formulario de subcategoría">
            <h2 className="hierarchy-subcategory-modal-form__title">
              {selectedSubcategory ? 'Editar subcategoría' : 'Crear subcategoría'}
            </h2>
            <InputValueForm label="Nombre de la categoría" name="categoryName" value={categoryName} isDisabled />
            <InputValueForm
              label="Nombre de la subcategoría"
              name="name"
              register={register}
              isInvalid={!!errors.name}
              helperText={errors.name?.message}
            />
            <FormGroup>
              <Label>Tipo de Asociación</Label>
              <AutocompleteForm
                source={HIERARCHY_ASSOCIATION_TYPES}
                name="associationType"
                getOptionValue={option => option.id}
                getOptionLabel={option => option.content}
                isInvalid={!!errors.associationType}
                isSearchable={false}
                isClearable={false}
                aria-label="Tipo de Asociación"
                helperText={errors.associationType?.message}
              />
            </FormGroup>
            {associationType === EHierarchyAssociation.BY_FAMILIES && (
              <FormGroup>
                <Label>Familias asociadas a la subcategoría</Label>
                <AutocompleteForm
                  source={familiesList}
                  name="families"
                  isLoading={loadingFamilies}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.description}
                  isMulti
                  isOptionDisabled={isOptionDisabled}
                  isInvalid={!!errors.families}
                  placeholder="Selecciona las familias"
                  aria-label="Familias asociadas a la subcategoría"
                  helperText={errors.families?.message}
                />
              </FormGroup>
            )}
            {associationType === EHierarchyAssociation.BY_SKUS && (
              <TextareaForm
                label="SKUs asociados a la subcategoría"
                {...register('skus')}
                isInvalid={!!errors.skus}
                placeholder="Escribe los SKU"
                aria-label="SKUs asociados a la subcategoría"
                helperText={errors.skus?.message}
              />
            )}
            <div className="hierarchy-subcategory-modal-form__actions">
              <Button type="submit" disabled={isButtonLoading}>
                Guardar
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </FormProvider>
  );
};
