import { Icon } from '@insuma/mpp-ui/components/icon';
import type { INavLink } from '../side-nav-link';

import './side-nav-button.scss';

interface ISideNavButtonProps {
  link?: INavLink;
  isActive: boolean;
}

export const SideNavButton = ({ link, isActive }: ISideNavButtonProps) => (
  <button
    type="button"
    className={`sidenav-button sidenav-button__link ${isActive ? 'sidenav-button__link--active' : ''}`}
    onClick={link?.action}
  >
    <div className="sidenav-button__icon-wrapper">
      {link?.icon && (
        <div className="sidenav-button__icon">
          <Icon name={link.icon} />
        </div>
      )}
    </div>
    <p className="sidenav-button__text">{link?.label}</p>
  </button>
);
