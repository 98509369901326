import { useFormContext } from 'react-hook-form';
import { useCSS } from '@insuma/mpp-ui/hooks';
import type { IDistributorForm } from 'core/model/interfaces/distributors';
import { DistributorSection } from 'screens/logistics/distributors/components/distributor-section';
import { InputValueForm } from 'shared/components/forms';

import './distributor-tax.scss';

export const DistributorTax = () => {
  const css = useCSS('distributor-tax');
  const { watch } = useFormContext<IDistributorForm>();
  const perception = watch('taxes.perception');

  return (
    <DistributorSection subtitle="5. Impuestos">
      <div className={css()}>
        <InputValueForm label="¿Aplica percepción?" value={perception ? 'Si' : 'No'} isDisabled />
      </div>
    </DistributorSection>
  );
};
