import { useFormContext } from 'react-hook-form';
import { Button } from '@insuma/mpp-ui/components/button';
import { FormGroup, Label } from '@insuma/mpp-ui/components/form';
import type { IShortcutFormData } from 'core/model/interfaces/customization';
import { DragAndDropImageUploaderForm, InputValueForm } from 'shared/components/forms';

import './shortcut-item-form.scss';

interface IShortcutItemFormProps {
  index: number;
  handleApplyClick: () => void;
  handleCancelClick: () => void;
}

export const ShortcutItemForm = ({ index, handleApplyClick, handleCancelClick }: IShortcutItemFormProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IShortcutFormData>();

  const errorTitle = errors?.shortcuts?.[index]?.title;
  const errorLink = errors?.shortcuts?.[index]?.link;
  const errorButtonName = errors?.shortcuts?.[index]?.buttonName;
  const errorImage = errors?.shortcuts?.[index]?.image;

  return (
    <div>
      <InputValueForm
        label="Nombre del shortcut (Máximo 20 caracteres)"
        name={`shortcuts.${index}.title` as const}
        register={register}
        isInvalid={!!errorTitle}
        helperText={errorTitle?.message}
      />
      <InputValueForm
        label="Enlace al que dirige"
        name={`shortcuts.${index}.link` as const}
        register={register}
        isInvalid={!!errorLink}
        helperText={errorLink?.message}
      />
      <InputValueForm
        label="Nombre del Botón (Máximo 20 caracteres)"
        name={`shortcuts.${index}.buttonName` as const}
        register={register}
        isInvalid={!!errorButtonName}
        helperText={errorButtonName?.message}
      />
      <FormGroup className="shortcut-item-form__image-container">
        <Label>Imagen principal</Label>
        <DragAndDropImageUploaderForm
          name={`shortcuts.${index}.image`}
          className="shortcut-item-form__image-uploader"
          isInvalid={!!errorImage}
          helperText={errorImage?.url?.message}
        />
      </FormGroup>
      <p>*Las medidas máximas recomendadas son de 400 x 300 px</p>
      <div className="shortcut-item-form__actions">
        <Button variant="secondary" onClick={() => handleApplyClick()}>
          Aplicar
        </Button>
        <Button variant="tertiary" onClick={() => handleCancelClick()}>
          Cancelar
        </Button>
      </div>
    </div>
  );
};
