import { HelperText } from '@insuma/mpp-ui/components/helper-text';

interface ISuggestionErrorParams {
  errors: any;
  name: string;
  sublevelName?: string;
  field: string;
}

export const SuggestionError = ({ errors, name, sublevelName, field }: ISuggestionErrorParams) => (
  <>
    {sublevelName && errors?.[name]?.[sublevelName]?.[field] && (
      <HelperText isInvalid>{errors[name][sublevelName][field].message}</HelperText>
    )}
    {!sublevelName && errors?.[name]?.[field] && <HelperText isInvalid>{errors[name][field].message}</HelperText>}
  </>
);
