import { useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Check, Text } from '@alicorpdigital/dali-components-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { RichEditor } from '@insuma/mpp-ui/components/editor';
import { FormGroup, Input, Label } from '@insuma/mpp-ui/components/form';
import { HelperText } from '@insuma/mpp-ui/components/helper-text';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { notification } from '@insuma/mpp-ui/notifications';
import { staticPagesRoutePath } from 'core/constants/routes.constants';
import { FOOTER_TREE_ID, MARKETPLACE_STATIC_PAGES_URL, SIDEBAR_TREE_ID } from 'core/constants/static-pages.constants';
import { EStaticPageStateType } from 'core/model/enums/static-pages.enum';
import type { IStaticPage } from 'core/model/interfaces/static-pages';
import { apiService } from 'core/services';
import { AutocompleteForm, InputValueForm } from 'shared/components/forms';
import { Link } from 'shared/components/link';
import { getErrorFromService } from 'shared/utils/https';
import { useNavigationTrees } from '../hooks/use-navigation-trees.hooks';
import { defaultStaticPage } from './default';
import { IStaticPageFormData, schema } from './schema';
import { normalizeSlug } from './utils';

import './static-pages-form.scss';

interface IStaticPageFormProps {
  onSubmit(data: IStaticPageFormData): void;
  mode: 'create' | 'edit';
  remoteData?: IStaticPage;
}

const mapRemoteDataToFormData = (remoteData: IStaticPage): IStaticPageFormData => ({
  ...remoteData,
  content: remoteData.content || '',
  trees: remoteData.treeIds.filter(t => t !== SIDEBAR_TREE_ID && t !== FOOTER_TREE_ID).map(t => t.toString()),
  inSidebarEnabled: remoteData.treeIds.length > 0 && remoteData.treeIds.includes(SIDEBAR_TREE_ID),
  inFooterEnabled: remoteData.treeIds.length > 0 && remoteData.treeIds.includes(FOOTER_TREE_ID),
  inNavigationTree: remoteData.treeIds.filter(id => id !== SIDEBAR_TREE_ID && id !== FOOTER_TREE_ID).length > 0,
  images: remoteData.images.map(image => ({ url: image, blob: null })),
  isPublic: !remoteData.isPrivate,
});

export const StaticPageForm = ({ mode, remoteData, onSubmit }: IStaticPageFormProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const [showSlugControls, setShowSlugControls] = useState(false);
  const { navigationTrees: navigationTreesOptions } = useNavigationTrees();
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: remoteData ? mapRemoteDataToFormData(remoteData) : defaultStaticPage,
  });

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    setValue,
    watch,
    trigger,
  } = formMethods;

  const formFieldsData = watch();
  const { title, slug, active, inNavigationTree } = formFieldsData;

  const prevSlug = useRef(slug);

  useEffect(() => {
    if ((mode === 'edit' && remoteData?.title !== title) || mode === 'create') {
      const normalizedSlug = normalizeSlug(title);
      prevSlug.current = normalizedSlug;
      setValue('slug', normalizedSlug);
      if (isDirty) trigger('slug');
    }
  }, [title, setValue, trigger, mode, remoteData, isDirty]);

  const handleUploadImage = async (file: File) => {
    const fileSize = Math.round(file.size / 1024);
    if (fileSize > 500) {
      throw new Error(`El tamaño del archivo (${fileSize}Kb) sobrepasa el límite permitido`);
    }

    const { successfulFiles, rejectedFiles } = await apiService.g2mFunctions.uploadFiles({
      file,
      context: 'statics',
      getFullFileUrl: true,
    });

    if (rejectedFiles.length !== 0) {
      notification.error('Error subiendo las imágenes');
    }

    if (successfulFiles.length > 0) {
      return successfulFiles[0].url.split('?')?.shift() ?? '';
    }

    return '';
  };

  const handleUploadImageError = (error: any) => {
    notification.error(error.message);
  };

  const handleSlugClick = () => setShowSlugControls(true);

  const handleSlugConfirm = () => {
    if (!errors?.slug?.message) {
      prevSlug.current = normalizeSlug(slug);
      setValue('slug', prevSlug.current);
      setShowSlugControls(false);
    }
  };

  const handleSlugCancel = () => {
    setValue('slug', prevSlug.current || (remoteData?.slug ?? ''));
    trigger('slug');
    setShowSlugControls(false);
  };

  const onFormSubmit = async (data: IStaticPageFormData) => {
    if (data.images.length === 0 && data.images.length === 0 && data.content === '<p></p>\n') {
      notification.error('Debe ingresar imágenes o el contenido de la página');
      return;
    }
    setIsSaving(true);
    try {
      onSubmit(data);
    } catch (error) {
      notification.error(getErrorFromService(error));
    }
    setIsSaving(false);
  };

  return (
    <FormProvider {...formMethods}>
      <div className="static-page-form">
        <Link icon="caret-left" to={`${staticPagesRoutePath}`}>
          Atrás
        </Link>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <h2 className="static-page-form__title">
            {mode === 'create' && 'Nueva Página Estática'}
            {mode === 'edit' && 'Editar Página Estática'}
          </h2>
          <div className="static-page-form__state">
            <FormGroup direction="horizontal">
              <span>Estado</span>
              <Controller
                name="active"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Check
                    type="switch"
                    label={active ? EStaticPageStateType.ENABLED : EStaticPageStateType.DISABLED}
                    checked={value}
                    onChange={onChange}
                    id="enabled"
                  />
                )}
              />
            </FormGroup>
          </div>
          <div className="static-page-form__card">
            <h4 className="static-page-form__subtitle">1. Información de la página</h4>
            <div className="static-page-form__half-row">
              <InputValueForm
                label="Nombre de la página"
                register={register}
                name="title"
                isInvalid={Boolean(errors?.title)}
                helperText={errors.title?.message}
              />
            </div>
            <div
              className={`static-page-form__full-row ${
                !showSlugControls ? 'static-page-form__full-row--centered' : ''
              }`}
            >
              <div className="static-page-form__url" onClick={handleSlugClick} aria-hidden="true">
                {MARKETPLACE_STATIC_PAGES_URL}
                <strong className="static-page-form__slug">{slug}</strong>
              </div>
              <div className="static-page-form__slug__actions">
                {!!showSlugControls && <Input size="sm" {...register('slug')} />}
                {showSlugControls ? (
                  <div className="static-page-form__slug__actions-buttons">
                    <Button iconName="check" isIconOnly size="xs" variant="tertiary" onClick={handleSlugConfirm} />
                    <Button iconName="x" isIconOnly size="xs" variant="tertiary" onClick={handleSlugCancel} />
                  </div>
                ) : (
                  <Icon
                    name="pencil"
                    size="sm"
                    onClick={handleSlugClick}
                    className="static-page-form__slug__actions-edit"
                  />
                )}
              </div>
            </div>
            {errors.slug && <HelperText isInvalid>{errors.slug.message}</HelperText>}
            <div>
              <h4 className="static-page-form__subtitle static-page-form__subtitle--plain">2. Privado o público</h4>
              <p>Las paginas están privadas por defecto y tienes que activarlas con está opción</p>
              <FormGroup className="static-page-form__private-input">
                <Check id="isPrivate" {...register('isPublic')} type="switch">
                  <Text as="span">Activar</Text>
                </Check>
              </FormGroup>
            </div>
            <div>
              <h4 className="static-page-form__subtitle">3. Contenido de la página</h4>
              <div className="static-page-form__editor-wrapper">
                {((mode === 'edit' && formFieldsData.title && formFieldsData.title !== '') || mode === 'create') && (
                  <Controller
                    name="content"
                    control={control}
                    render={({ field: { value, onChange, ...restOfProps } }) => (
                      <RichEditor
                        onUploadImage={handleUploadImage}
                        value={value}
                        onChange={onChange}
                        onImageUploadError={handleUploadImageError}
                        {...restOfProps}
                      />
                    )}
                  />
                )}
              </div>
              {errors.content && <HelperText isInvalid>{errors.content.message}</HelperText>}
            </div>
            <div className="static-page-form__fitted-card ">
              <h4 className="static-page-form__subtitle">4. Mostrar en</h4>
              <FormGroup>
                <Check id="inFooterEnabled" {...register('inFooterEnabled')} type="switch">
                  <Text as="span">Footer</Text>
                </Check>
              </FormGroup>
              <FormGroup>
                <Check id="inSidebarEnabled" {...register('inSidebarEnabled')} type="switch">
                  <Text as="span">Barra del menú</Text>
                </Check>
              </FormGroup>
              <FormGroup>
                <Check id="inNavigationTree" {...register('inNavigationTree')} type="switch">
                  <Text as="span">Categorías (Árbol de navegación)</Text>
                </Check>
              </FormGroup>
              {inNavigationTree && (
                <FormGroup className="static-page-form__trees-input">
                  <Label>Selecciona el árbol de navegación</Label>
                  <AutocompleteForm
                    source={navigationTreesOptions}
                    name="trees"
                    isMulti
                    getOptionValue={option => option.treeId.toString()}
                    getOptionLabel={option => option.name}
                    helperText={errors.trees?.message}
                    isInvalid={Boolean(errors.trees)}
                  />
                </FormGroup>
              )}
            </div>
          </div>
          <div className="static-page-form__actions">
            <Button type="submit" disabled={isSaving}>
              Guardar
            </Button>
          </div>
        </form>
      </div>
    </FormProvider>
  );
};
