import { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse } from '@insuma/mpp-ui/components/animations';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { accessibleOnClick } from 'shared/utils/a11y.utils';
import type { INavLink } from '../side-nav-link';

import './side-nav-dropdown.scss';

interface ISideDropdownProps {
  currentPath: string;
  link: INavLink;
}

export const SideDropdown = ({ currentPath, link }: ISideDropdownProps) => {
  const [openSublinks, setOpenSublinks] = useState<boolean>(false);
  const isMainActive = link.to ? currentPath.startsWith(link.to) : false;

  useLayoutEffect(() => {
    setOpenSublinks(isMainActive);
  }, [isMainActive]);

  return (
    <div className="side-nav-dropdown">
      <div
        {...accessibleOnClick(() => setOpenSublinks(!openSublinks))}
        className={`side-nav-dropdown__link ${isMainActive ? 'side-nav-dropdown__link--active' : ''} justify-content`}
      >
        <div className="side-nav-dropdown__icon-wrapper">
          {link.icon && (
            <div className="side-nav-dropdown__icon">
              <Icon name={link.icon} />
              <span className="side-nav-dropdown__text--dropdown">{link.label}</span>
            </div>
          )}
        </div>
        <div>{openSublinks ? <Icon name="caret-up" /> : <Icon name="caret-down" />}</div>
      </div>
      <Collapse show={openSublinks} duration={300}>
        <div>
          {link.sublinks?.map(link => {
            const isActive = currentPath === link.to;
            return (
              <Link
                className={`side-nav-dropdown__link ${isActive ? 'side-nav-dropdown__link--active' : ''}`}
                key={link.to}
                to={link.to || ''}
              >
                <div className="side-nav-dropdown__icon-wrapper">
                  {link.icon && (
                    <div className="side-nav-dropdown__icon">
                      <Icon name={link.icon} />
                    </div>
                  )}
                </div>
                <p className="side-nav-dropdown__text">{link.label}</p>
              </Link>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};
