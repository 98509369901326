import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { FormGroup, MultipleDatePicker } from '@insuma/mpp-ui/components/form';
import { HelperText } from '@insuma/mpp-ui/components/helper-text';
import { DAY_NAMES, DEFAULT_DELIVERY_CONDITION, TEnglishDay } from 'core/constants/logistics.constants';
import { deliveryConditionRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { TDeliveryConditionFormData } from 'core/model/interfaces/delivery-condition';
import { CheckboxSelector } from 'shared/components/checkbox-selector';
import { CustomEditor } from 'shared/components/custom-editor';
import { InputValueForm } from 'shared/components/forms';
import { Link } from 'shared/components/link';
import { getDropdownDeliveryOptions, resetDeliveryMap } from '../../delivery-condition.utils';
import { DeliveryDaysTable } from '../delivery-days-table';
import { DeliveryHolidaysTable } from '../delivery-holidays-table';
import { schema } from './schema';

import './delivery-condition-form.scss';

interface IDeliveryConditionFormProps {
  onSubmit(data: TDeliveryConditionFormData): void;
  deliveryCondition?: TDeliveryConditionFormData;
  isSaving?: boolean;
  mode: 'edit' | 'create';
}

export const DeliveryConditionForm = ({
  isSaving = false,
  deliveryCondition,
  onSubmit,
  mode,
}: IDeliveryConditionFormProps) => {
  const defaultValues: TDeliveryConditionFormData = structuredClone(deliveryCondition || DEFAULT_DELIVERY_CONDITION);

  const formMethods = useForm<TDeliveryConditionFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    resetField,
    watch,
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = formMethods;

  const selectedDeliveryDays = watch('availableDeliveryDays');
  const currentDeliveryMap = watch('purchaseDayDeliveryConfigurations');
  const [deliveryOptions, setDeliveryOptions] = useState(() =>
    getDropdownDeliveryOptions(defaultValues.availableDeliveryDays),
  );

  const handleDeliveryDaysChange = (values: Array<string>) => {
    const generatedOptions = getDropdownDeliveryOptions(values as Array<TEnglishDay>);
    setDeliveryOptions(generatedOptions);
    resetField('purchaseDayDeliveryConfigurations', {
      defaultValue: resetDeliveryMap(generatedOptions, currentDeliveryMap),
    });
  };

  return (
    <div className="delivery-condition-form">
      <Link icon="caret-left" to={`${logisticsRoutePath}${deliveryConditionRoutePath}`}>
        Atrás
      </Link>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="delivery-condition-form__title">
          {mode === 'create' ? 'Nueva' : 'Editar'} condición de entrega
        </h2>
        <div>
          <div className="row-container">
            <h4 className="delivery-condition-form__subtitle">1. Configuración de fecha</h4>
            <div className="delivery-condition-form__form-section">
              <InputValueForm
                register={register}
                label="Nombre de condición de entrega"
                name="deliveryConfigurationName"
                isInvalid={!!errors.deliveryConfigurationName}
                helperText={errors.deliveryConfigurationName?.message}
              />
            </div>
          </div>
          <br />
          <div className="row-container">
            <h4 className="delivery-condition-form__subtitle">2. Días de entrega</h4>
            <div className="delivery-condition-form__form-section">
              <FormGroup>
                <CheckboxSelector
                  customOnChange={handleDeliveryDaysChange}
                  name="availableDeliveryDays"
                  control={control}
                  optionsList={DAY_NAMES}
                />
                {errors.availableDeliveryDays && (
                  <HelperText isInvalid>{errors.availableDeliveryDays.message}</HelperText>
                )}
              </FormGroup>
            </div>
          </div>
          <br />
          <div className="row-container">
            <h4 className="delivery-condition-form__subtitle">3. Entregas según día de compra</h4>
            <div>
              <Controller
                name="purchaseDayDeliveryConfigurations"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DeliveryDaysTable
                    errors={errors}
                    deliveryDays={selectedDeliveryDays}
                    deliveryOptions={deliveryOptions}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>
          </div>
          <br />
          <div className="row-container">
            <h4 className="delivery-condition-form__subtitle">4. Entrega para días festivos</h4>
            <Controller
              name="additionalDaysForRestrictedDates"
              control={control}
              render={({ field: { value, onChange } }) => <DeliveryHolidaysTable value={value} onChange={onChange} />}
            />
          </div>
          <br />
          <div className="row-container">
            <h4 className="delivery-condition-form__subtitle">5. Días festivos sin entrega</h4>
            <p className="delivery-condition-form__description">Selecciona los días festivos:</p>
            <div className="delivery-condition-form__form-section">
              <Controller
                name="restrictedDeliveryDates"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <MultipleDatePicker
                    helperText={errors.restrictedDeliveryDates ? errors.restrictedDeliveryDates.message : ''}
                    value={value}
                    onChange={onChange}
                    isInvalid={!!errors.restrictedDeliveryDates}
                  />
                )}
              />
            </div>
          </div>
          <br />
          <div className="row-container">
            <h4 className="delivery-condition-form__subtitle">6. Día máximo a mostrar</h4>
            <p className="delivery-condition-form__description">
              Limitar el máximo de opciones de día de entrega que se ofrecen al cliente en el momento de la compra.
            </p>
            <div className="delivery-condition-form__form-section">
              <InputValueForm
                register={register}
                name="dateOptionsCount"
                className="special-days-input"
                suffix="día"
                placeholder=""
                isInvalid={!!errors.dateOptionsCount}
                helperText={errors.dateOptionsCount?.message}
              />
            </div>
          </div>
          <br />
          <div className="delivery-information row-container">
            <h4 className="delivery-condition-form__subtitle">7. Información de entrega en checkout</h4>
            <div>
              <FormGroup>
                <p className="delivery-condition-form__description">
                  Texto que se mostrará en el checkout. Se recomienda incluir información relacionada con la entrega.
                </p>
                <Controller
                  name="description"
                  control={control}
                  render={({ field: { value, onChange } }) => <CustomEditor value={value} onChange={onChange} />}
                />

                <br />
                {errors.description && <HelperText isInvalid>{errors.description.message}</HelperText>}
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="delivery-condition-form__actions">
          <Button type="submit" isLoading={isSaving} width="15rem">
            Guardar
          </Button>
        </div>
      </form>
    </div>
  );
};
