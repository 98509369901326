import type { ICustomerCommercialData } from 'core/model/interfaces/customers';
import { CustomerFormDivider, CustomerFormLayout } from 'screens/customers/customer-form/components';
import { InputValueForm } from 'shared/components/forms';

interface ICustomerCommercialDataProps {
  commercialData: ICustomerCommercialData;
}
export const CustomerCommercialData = ({ commercialData }: ICustomerCommercialDataProps) => {
  const name = commercialData.documentType !== 'RUC' ? commercialData.name : '';
  const lastNames =
    commercialData.documentType !== 'RUC' ? `${commercialData.firstLastName} ${commercialData.secondLastName}` : '';
  const businessName = commercialData.documentType === 'RUC' ? commercialData.businessName : '';

  return (
    <section>
      <CustomerFormLayout columns="three">
        <InputValueForm label="Razón social" name="Razón social" isDisabled value={businessName} />
        <InputValueForm label="Nombres" name="Nombres" isDisabled value={name} />
        <InputValueForm label="Apellidos" name="Apellidos" isDisabled value={lastNames} />
        <InputValueForm
          label="Tipo de documento"
          name="Tipo de documento"
          isDisabled
          value={commercialData.documentType}
        />
        <InputValueForm
          label="Número de identificación"
          name="Número de identificación"
          isDisabled
          value={commercialData.documentNumber}
        />
        <InputValueForm
          label="Número de celular"
          name="Número de celular"
          isDisabled
          value={commercialData.primaryPhone}
        />
        <InputValueForm
          label="Número de contacto 2"
          name="Número de contacto 2"
          isDisabled
          value={commercialData.secondaryPhone}
        />
        <InputValueForm label="Correo Electrónico" name="Correo Electrónico" isDisabled value={commercialData.email} />
        <InputValueForm
          label="Dirección fiscal / Dirección domicilio"
          name="Dirección fiscal / Dirección domicilio"
          isDisabled
          value={commercialData.address}
        />
      </CustomerFormLayout>
      <CustomerFormDivider />
      <CustomerFormLayout columns="three">
        <InputValueForm
          label="Grupo cliente Dex"
          name="Grupo cliente Dex"
          isDisabled
          value={commercialData.dexCustomerGroup}
        />
        <InputValueForm
          label="Grupo de precios Dex"
          name="Grupo de precios Dex"
          isDisabled
          value={commercialData.dexPricesGroup}
        />
        <InputValueForm
          label="Lista de precios Dex"
          name="Lista de precios Dex"
          isDisabled
          value={commercialData.dexPricesList}
        />
      </CustomerFormLayout>
      <CustomerFormDivider />
      <CustomerFormLayout>
        <InputValueForm
          label="Flag Lead Digital"
          name="Flag Lead Digital"
          isDisabled
          value={commercialData.digitalLeadFlag}
        />
      </CustomerFormLayout>
    </section>
  );
};
