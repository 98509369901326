import type { ICustomerVisitData } from 'core/model/interfaces/customers';
import { CustomerFormDivider, CustomerFormLayout } from 'screens/customers/customer-form/components';
import { InputValueForm } from 'shared/components/forms';

interface ICustomerVisitDataProps {
  visitData: ICustomerVisitData;
}

export const CustomerVisitData = ({ visitData }: ICustomerVisitDataProps) => (
  <section>
    <CustomerFormLayout columns="two">
      <InputValueForm value={visitData.sellerName} isDisabled label="Vendedor" name="visitData.sellerName" />
      <InputValueForm
        value={visitData.sellerEmail}
        isDisabled
        label="Correo del vendedor"
        name="visitData.sellerEmail"
      />
      <InputValueForm value={visitData.campus} isDisabled label="Sede" name="visitData.campus" />
      <InputValueForm
        value={visitData.saleZoneCode}
        isDisabled
        label="Código de zona de ventas"
        name="visitData.saleZoneCode"
      />
    </CustomerFormLayout>
    <CustomerFormDivider />
    <CustomerFormLayout columns="two">
      <InputValueForm value={visitData.dayOfVisit} isDisabled label="Día de visita" name="visitData.dayOfVisit" />
      <InputValueForm
        value={visitData.leadDeliveryTime}
        isDisabled
        label="Ventana horaria"
        name="visitData.leadDeliveryTime"
      />
    </CustomerFormLayout>
  </section>
);
