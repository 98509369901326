import { Button } from '@insuma/mpp-ui/components/button';
import type { IColumnProps } from '@insuma/mpp-ui/components/table';
import type { IDisplayCustomer } from 'core/model/interfaces/customers';

export const getColumns = ({
  handleClick,
}: {
  handleClick(row: number): void;
}): Array<IColumnProps<IDisplayCustomer>> => [
  { header: 'Documento', accessor: 'documentNumber' },
  {
    header: 'Razón Social',
    accessor: 'businessName',
  },
  {
    header: 'Lista de precios',
    accessor: 'priceList',
  },
  {
    header: '',
    accessor: 'events',
    render: row => (
      <div>
        <Button size="sm" isIconOnly iconName="eye" variant="tertiary" onClick={() => handleClick(row.id)} />
      </div>
    ),
  },
];
