import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { Button } from '@insuma/mpp-ui/components/button';
import { Icon } from '@insuma/mpp-ui/components/icon';
import Drag from 'assets/drag.svg';
import type {
  IHierarchyTreeCategory,
  IHierarchyTreeSubcategory,
} from 'core/model/interfaces/navigation/hierarchy.interface';
import { DndSortable } from 'shared/dnd-sortable';
import { SubcategoryListItem } from '../subcategory-list-item/subcategory-list-item';

import './category-list-item.scss';

interface ICategoryListItemProps {
  category: IHierarchyTreeCategory;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  isDeleteDisabled?: boolean;
  isCollapsed?: boolean;
  onEdit?(): void;
  onDelete?(id: IHierarchyTreeCategory): void;
  onCreateSubcategory?(): void;
  onDeleteSubcategory?(subcategory: IHierarchyTreeSubcategory): void;
  onChangeCollapsed?(collapsed: boolean): void;
  onChangeSubcategories?(subcategories: Array<IHierarchyTreeSubcategory>): void;
  onEditSubcategory?(subcategory: IHierarchyTreeSubcategory): void;
}

export const CategoryListItem = ({
  category,
  attributes,
  listeners,
  onDelete,
  onEdit,
  onDeleteSubcategory,
  onCreateSubcategory,
  onEditSubcategory,
  onChangeCollapsed,
  onChangeSubcategories,
  isDeleteDisabled = false,
  isCollapsed = true,
}: ICategoryListItemProps) => {
  const { subcategories, name } = category;

  const handleSubcategoriesChange = (values: Array<IHierarchyTreeSubcategory>) => {
    if (onChangeSubcategories) onChangeSubcategories(values);
  };

  return (
    <div className="category-list-item">
      <div className="category-list-item__card">
        <img src={Drag} alt="" {...attributes} {...listeners} />
        <span
          aria-hidden="true"
          className="category-list-item__title"
          onClick={() => onChangeCollapsed && onChangeCollapsed(!isCollapsed)}
        >
          {name}({subcategories.length}) <Icon name={isCollapsed ? 'caret-down' : 'caret-up'} />
        </span>
        <div className="category-list-item__actions">
          <Button
            iconName="plus"
            isIconOnly
            variant="tertiary"
            onClick={() => onCreateSubcategory && onCreateSubcategory()}
          />
          <Button iconName="pencil" isIconOnly variant="tertiary" onClick={() => onEdit && onEdit()} />
          <Button
            iconName="trash"
            isIconOnly
            variant="tertiary"
            onClick={() => onDelete && onDelete(category)}
            disabled={isDeleteDisabled}
          />
        </div>
      </div>
      {isCollapsed && subcategories && (
        <div className="category-list-item__subcategories">
          <DndSortable
            items={subcategories}
            renderItem={item => (
              <SubcategoryListItem
                subcategory={item}
                isDeleteDisabled={subcategories.length === 1}
                onDelete={() => onDeleteSubcategory && onDeleteSubcategory(item)}
                onEdit={() => onEditSubcategory && onEditSubcategory(item)}
              />
            )}
            onChange={handleSubcategoriesChange}
          />
        </div>
      )}
    </div>
  );
};
