import { Button } from '@insuma/mpp-ui/components/button';
import type { IColumnProps } from '@insuma/mpp-ui/components/table';
import dayjs from 'dayjs';
import type { IDisplayStaticPage } from 'core/model/interfaces/static-pages';

export const getColumns = ({
  onEdit,
  onDelete,
}: {
  onEdit(row: IDisplayStaticPage): void;
  onDelete(row: IDisplayStaticPage): void;
}): Array<IColumnProps<IDisplayStaticPage>> => [
  { header: 'Nombre', accessor: 'title' },
  {
    header: 'Fecha de creación',
    accessor: 'createdAt',
    render: row => <span>{dayjs(row.createdAt).format('DD/MM/YYYY')}</span>,
  },
  {
    header: 'Visualización',
    accessor: '',
    render: row => <span>{row.navigations.length > 0 ? row.navigations.join(', ') : 'Ninguno'}</span>,
  },
  {
    header: 'Estado',
    accessor: 'state',
    render: row => (
      <span
        className={`static-pages-list__state-column static-pages-list__state-column--${
          row.active ? 'active' : 'inactive'
        }`}
      >
        {row.active ? 'Activado' : 'Inactivo'}
      </span>
    ),
  },
  {
    header: '',
    accessor: 'events',
    render: row => (
      <div>
        <Button size="sm" isIconOnly iconName="pencil" variant="tertiary" onClick={() => onEdit(row)} />
        <Button size="sm" isIconOnly iconName="trash" variant="tertiary" onClick={() => onDelete(row)} />
      </div>
    ),
  },
];
