import { Button } from '@insuma/mpp-ui/components/button';
import type { IColumnProps } from '@insuma/mpp-ui/components/table';
import type { IDisplayCustomization } from 'core/model/interfaces/customization';

export const getCustomizationColumns = (
  onView: (id: number) => void,
  onEdit: (id: number) => void,
  onDelete: (row: IDisplayCustomization) => void,
): Array<IColumnProps<IDisplayCustomization>> => [
  {
    header: 'Nombre',
    accessor: 'name',
  },
  {
    header: 'Operador',
    accessor: 'externalDistributors',
    render: row => <span>{row.externalDistributors}</span>,
  },
  {
    header: 'Lista de precios',
    accessor: 'externalPriceLists',
    render: row => <span>{row.externalPriceLists}</span>,
  },
  {
    header: '',
    accessor: 'events',
    render: row => (
      <div className="customization-list__table-actions">
        <Button
          isIconOnly
          size="sm"
          iconName="load-balancer-classic"
          variant="tertiary"
          onClick={() => onView(row.id)}
        />
        <Button isIconOnly size="sm" iconName="pencil" variant="tertiary" onClick={() => onEdit(row.id)} />
        <Button isIconOnly size="sm" iconName="trash" variant="tertiary" onClick={() => onDelete(row)} />
      </div>
    ),
  },
];
