import { useFormContext } from 'react-hook-form';
import { FormGroup, Label } from '@insuma/mpp-ui/components/form';
import { IGroupingRulesFormData } from 'core/model/interfaces/grouping-rules';
import { useDeliveryConfigurations } from 'screens/logistics/grouping-rules/hooks/use-delivery-configurations.hooks';
import { AutocompleteForm } from 'shared/components/forms';

import './grouping-rules-delivery-date.scss';

const GroupingRulesDeliveryDate = () => {
  const {
    formState: { errors },
  } = useFormContext<IGroupingRulesFormData>();

  const { deliveryConfigurationList, loading: loadingDeliveryConfiguration } = useDeliveryConfigurations();

  return (
    <div className="row-container">
      <h4 className="row-container__subtitle">4. Fechas de entrega asociadas a esta agrupación</h4>
      <div className="delivery-date">
        <FormGroup>
          <Label>Fecha de entrega</Label>
          <AutocompleteForm
            name="deliveryConfiguration"
            source={deliveryConfigurationList}
            isLoading={loadingDeliveryConfiguration}
            placeholder="Selecciona nombre fecha de entrega"
            getOptionLabel={option => option.deliveryConfigurationName}
            getOptionValue={option => `${option.id}`}
            isClearable={false}
            isObjectValue
            isInvalid={!!errors.deliveryConfiguration}
            helperText={errors.deliveryConfiguration?.message}
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default GroupingRulesDeliveryDate;
