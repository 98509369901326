import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from '@insuma/mpp-ui/components/button';
import { FormGroup, Label } from '@insuma/mpp-ui/components/form';
import type { IBannerListForm } from 'core/model/interfaces/banners';
import { DatePickerForm, DragAndDropImageUploaderForm, InputValueForm } from 'shared/components/forms';

import './banner-form.scss';

interface IBannerFormProps {
  index: number;
  onSaveClick(index: number): void;
  onCancelClick(index: number): void;
}

export const BannerForm = ({ index, onCancelClick, onSaveClick }: IBannerFormProps) => {
  const {
    control,
    register,
    formState: { errors },
    trigger,
  } = useFormContext<IBannerListForm>();

  const banner = useWatch<IBannerListForm, 'banners'>({
    control,
    name: 'banners',
  })[index];

  const onSaveChanges = async () => {
    const hasentErrors = await trigger(`banners.${index}`);
    if (hasentErrors) onSaveClick(index);
  };

  const errorName = errors.banners?.[index]?.name;
  const errorDesktopImage = errors.banners?.[index]?.desktopImage?.url;
  const errorMobileImage = errors.banners?.[index]?.mobileImage?.url;
  const errorDateRange = errors.banners?.[index]?.dateRange;
  const errorViewOrder = errors.banners?.[index]?.viewOrder;
  const errorLink = errors.banners?.[index]?.link;

  return (
    <div className="banner-form">
      <InputValueForm
        label="Nombre del banner"
        register={register}
        name={`banners.${index}.name`}
        placeholder="Nombre de banner"
        isInvalid={!!errorName}
        helperText={errorName?.message}
      />

      <div className="banner-form__images-section">
        <FormGroup>
          <h3 className="banner-form__subtitle">Banner desktop</h3>
          <DragAndDropImageUploaderForm
            name={`banners.${index}.desktopImage`}
            isInvalid={!!errorDesktopImage}
            helperText={errorDesktopImage?.message}
          />
          {!banner.desktopImage?.url && (
            <p className="banner-form__helper">*Las medidas máximas recomendadas son de 1980 x 532 px</p>
          )}
        </FormGroup>

        <FormGroup>
          <h3 className="banner-form__subtitle">Banner mobile</h3>
          <DragAndDropImageUploaderForm
            name={`banners.${index}.mobileImage`}
            isInvalid={!!errorMobileImage}
            helperText={errorMobileImage?.message}
          />
          {!banner.mobileImage?.url && (
            <p className="banner-form__helper">*Las medidas máximas recomendadas son de 1980 x 532 px</p>
          )}
        </FormGroup>
      </div>

      <div className="banner-form__information">
        <h3 className="banner-form__subtitle">Información de banner</h3>
        <FormGroup>
          <Label>Fecha de inicio - fecha final</Label>
          <DatePickerForm
            name={`banners.${index}.dateRange`}
            minDate={new Date()}
            selectRange
            placeholder="dd/mm/aaaa - dd/mm/aaaa"
            hasIcon
            onBlurProp={() => trigger(`banners.${index}.dateRange`)}
            isInvalid={!!errorDateRange}
            helperText={errorDateRange?.message}
          />
        </FormGroup>
        <InputValueForm
          label="Orden de vista"
          register={register}
          name={`banners.${index}.viewOrder`}
          placeholder="Ejemplo: 1"
          type="number"
          isInvalid={!!errorViewOrder}
          helperText={errorViewOrder?.message}
        />
      </div>

      <h3 className="banner-form__subtitle">Enlace</h3>
      <InputValueForm
        register={register}
        name={`banners.${index}.link`}
        placeholder="Ejemplo: www.insuma.com.pe"
        isInvalid={!!errorLink}
        helperText={errorLink?.message}
        label="Enlace al que dirige"
      />

      <div className="banner-form__actions">
        <Button type="button" variant="secondary" onClick={onSaveChanges}>
          Aplicar
        </Button>

        <Button type="button" variant="tertiary" onClick={() => onCancelClick(index)}>
          Cancelar
        </Button>
      </div>
    </div>
  );
};
