import { useFormContext } from 'react-hook-form';
import { FormGroup, Label } from '@insuma/mpp-ui/components/form';
import { HIERARCHY_ASSOCIATION_TYPES } from 'core/constants/hierarchy.constants';
import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';
import type {
  IHierarchyTreeCategory,
  IHierarchyTreeCategoryCreateForm,
  IHierarchyTreeFamily,
} from 'core/model/interfaces/navigation/hierarchy.interface';
import { useHierarchyTreeFamilies } from 'screens/products/navigation-tree/hierarchy-tree/hooks';
import { AutocompleteForm, InputValueForm } from 'shared/components/forms';
import { TextareaForm } from 'shared/components/forms/textarea-form';

interface IHierarchyCategorySubcategoryFieldsProps {
  selectedCategory?: IHierarchyTreeCategory;
  treeId: string;
}

export const HierarchyCategorySubcategoryFields = ({
  selectedCategory,
  treeId,
}: IHierarchyCategorySubcategoryFieldsProps) => {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<IHierarchyTreeCategoryCreateForm>();
  const associationType = watch('associationType');
  const { familiesList, loading: loadingFamilies } = useHierarchyTreeFamilies({
    treeId,
    enabled: !selectedCategory,
  });

  const isOptionDisabled = (family: IHierarchyTreeFamily) => !family.available;

  return (
    <>
      <InputValueForm
        label="Nombre de la subcategoría"
        name="subcategoryName"
        register={register}
        isInvalid={!!errors.subcategoryName}
        helperText={errors.subcategoryName?.message}
      />
      <FormGroup>
        <Label>Tipo de Asociación</Label>
        <AutocompleteForm
          source={HIERARCHY_ASSOCIATION_TYPES}
          name="associationType"
          getOptionValue={option => option.id}
          getOptionLabel={option => option.content}
          isInvalid={!!errors.associationType}
          isSearchable={false}
          isClearable={false}
          aria-label="Tipo de Asociación"
          helperText={errors.associationType?.message}
        />
      </FormGroup>
      {associationType === EHierarchyAssociation.BY_FAMILIES && (
        <FormGroup>
          <Label>Familias asociadas a la subcategoría</Label>
          <AutocompleteForm
            source={familiesList}
            name="families"
            isLoading={loadingFamilies}
            getOptionValue={option => option.id}
            getOptionLabel={option => option.description}
            isMulti
            isOptionDisabled={isOptionDisabled}
            isInvalid={!!errors.families}
            placeholder="Selecciona las familias"
            aria-label="Familias asociadas a la subcategoría"
            helperText={errors.families?.message}
          />
        </FormGroup>
      )}
      {associationType === EHierarchyAssociation.BY_SKUS && (
        <TextareaForm
          label="SKUs asociados a la subcategoría"
          {...register('skus')}
          isInvalid={!!errors.skus}
          placeholder="Escribe los SKU"
          aria-label="SKUs asociados a la subcategoría"
          helperText={errors.skus?.message}
        />
      )}
    </>
  );
};
