import { Button } from '@insuma/mpp-ui/components/button';

import './category-form-submit-button.scss';

interface IHierarchyCategorySubmitButtonProps {
  isDisabled: boolean;
}

export const HierarchyCategorySubmitButton = ({ isDisabled }: IHierarchyCategorySubmitButtonProps) => (
  <div className="hierarchy-category-form-submit-button">
    <Button disabled={isDisabled} type="submit">
      Guardar
    </Button>
  </div>
);
