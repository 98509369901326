import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Check } from '@alicorpdigital/dali-components-react';
import { FormGroup } from '@insuma/mpp-ui/components/form';
import { ESuggestedProductStateType } from 'core/model/enums/suggested-product.enum';
import { ISuggestion } from 'core/model/interfaces/suggested-product';
import { InputValueForm } from 'shared/components/forms';
import { SuggestionError } from '../suggestion-error/suggestion-error';
import { SuggestionTypes } from '../suggestion-types/suggestion-types';

interface ISuggestionGeneralDetailsProps {
  name: keyof ISuggestion;
  sublevelName?: string;
  watchedValue: boolean;
  titleLabel: string;
  titlePlaceholder?: string;
  typesSelection?: boolean;
  limitSuggestions?: boolean;
  stateTitle?: string;
}
// TODO: Revisar componente para aprovechar el uso de helpertext en InputValueForm
export const SuggestionDetails = ({
  name,
  sublevelName,
  watchedValue,
  titleLabel,
  titlePlaceholder,
  typesSelection = false,
  limitSuggestions = true,
  stateTitle,
}: ISuggestionGeneralDetailsProps) => {
  const {
    register,
    trigger,
    formState: { errors },
  } = useFormContext();
  const fieldName = sublevelName ? `${name}.${sublevelName}` : name;

  useEffect(() => {
    trigger(name);
  }, [name, trigger, watchedValue]);
  return (
    <>
      <div className="suggested-product-form__section">
        <InputValueForm
          label={titleLabel}
          placeholder={titlePlaceholder}
          name={`${fieldName}.title`}
          register={register}
        >
          <SuggestionError errors={errors} name={name} sublevelName={sublevelName} field="title" />
        </InputValueForm>
        {limitSuggestions && (
          <InputValueForm label="N° máximo de sugerencias" name={`${fieldName}.maxSuggestions`} register={register}>
            <SuggestionError errors={errors} name={name} sublevelName={sublevelName} field="maxSuggestions" />
          </InputValueForm>
        )}
      </div>
      {typesSelection && (
        <div>
          <h4 className="suggested-product-form__subtitle">2. Elegir opción a mostrar:</h4>
          <div className="suggested-product-form__section">
            <SuggestionTypes name={fieldName} />
            <SuggestionError errors={errors} name={name} field="option" />
          </div>
        </div>
      )}

      {stateTitle && <h4 className="suggested-product-form__subtitle">{stateTitle}</h4>}
      <FormGroup className="suggested-product-form__state">
        <Check
          {...register(`${fieldName}.state` as keyof ISuggestion)}
          size="sm"
          type="switch"
          label={watchedValue ? ESuggestedProductStateType.ENABLED : ESuggestedProductStateType.DISABLED}
          id={`${fieldName}State`}
        />
      </FormGroup>
    </>
  );
};
