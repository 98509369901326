import type { ICustomerDeliveryAddress } from 'core/model/interfaces/customers';
import { CustomerFormLayout } from 'screens/customers/customer-form/components';
import { InputValueForm } from 'shared/components/forms';

interface IAddressItemProps {
  address: ICustomerDeliveryAddress;
}

const DeliveryAddressItem = ({
  address: { address, department, district, province, ubigeoCode },
}: IAddressItemProps) => (
  <CustomerFormLayout>
    <InputValueForm value={address} isDisabled label="Dirección Principal" name="address" />
    <CustomerFormLayout columns="two">
      <InputValueForm value={district} isDisabled label="Distrito" name="district" />
      <InputValueForm value={province} isDisabled label="Provincia" name="province" />
      <InputValueForm value={department} isDisabled label="Departamento" name="department" />
      <InputValueForm value={ubigeoCode} isDisabled label="Ubigeo" name="ubigeoCode" />
    </CustomerFormLayout>
  </CustomerFormLayout>
);

export default DeliveryAddressItem;
