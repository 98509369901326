import { Check, Text } from '@alicorpdigital/dali-components-react';
import type { ICustomerPaymentTypes } from 'core/model/interfaces/customers';
import {
  CustomerFormContainer,
  CustomerFormLayout,
  CustomerFormList,
  CustomerFormSubtitle,
  CustomerFormSwitchContainer,
} from 'screens/customers/customer-form/components';
import { InputValueForm } from 'shared/components/forms';

interface ICustomerPaymentProps {
  title: string;
  paymentTypes: ICustomerPaymentTypes;
}
export const CustomerPayment = ({ paymentTypes: { credit, cash }, title }: ICustomerPaymentProps) => (
  <CustomerFormContainer type="outlined">
    <CustomerFormLayout smallGaps>
      <CustomerFormSubtitle standalone>{title}</CustomerFormSubtitle>
      <CustomerFormContainer type="outlined">
        <CustomerFormLayout smallGaps>
          <CustomerFormSwitchContainer>
            <CustomerFormSubtitle standalone>Pago al contado</CustomerFormSubtitle>
            <Check type="switch" checked={cash.status} disabled>
              <Text as="span">{cash.status ? 'Activo' : 'Inactivo'}</Text>
            </Check>
          </CustomerFormSwitchContainer>
          <CustomerFormList>
            {cash.paymentMethods?.map((method, index) => (
              <p key={index}>{method.description}</p>
            ))}
          </CustomerFormList>
        </CustomerFormLayout>
      </CustomerFormContainer>
      <CustomerFormContainer type="outlined">
        <CustomerFormLayout smallGaps>
          <CustomerFormSwitchContainer>
            <CustomerFormSubtitle standalone>Pago a crédito</CustomerFormSubtitle>
            <Check type="switch" checked={credit.status} disabled>
              <Text as="span">{credit.status ? 'Activo' : 'Inactivo'}</Text>
            </Check>
          </CustomerFormSwitchContainer>

          <CustomerFormLayout>
            <InputValueForm
              label="Importe de linea de crédito"
              name="Importe de linea de crédito"
              value={credit.creditLineAmount}
              isDisabled
            />
            <InputValueForm
              label="Importe de la linea de crédito disponible"
              name="Importe de la linea de crédito disponible"
              value={credit.availableCreditLineAmount}
              isDisabled
            />
            <InputValueForm
              label="Importe de la linea de crédito comprometida"
              name="Importe de la linea de crédito comprometida"
              value={credit.busyAvailableCreditLineAmount}
              isDisabled
            />
            <InputValueForm label="Plazo de pago" name="Plazo de pago" value={credit.paymentTerm} isDisabled />
            <InputValueForm label="Motivo de deuda" name="Motivo de deuda" value={credit.debtReason} isDisabled />
          </CustomerFormLayout>
        </CustomerFormLayout>
      </CustomerFormContainer>
    </CustomerFormLayout>
  </CustomerFormContainer>
);
