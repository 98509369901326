import { useFormContext } from 'react-hook-form';
import { useCSS } from '@insuma/mpp-ui/hooks';
import type { IDistributorForm } from 'core/model/interfaces/distributors';
import { DistributorSection } from 'screens/logistics/distributors/components/distributor-section';
import { InputValueForm } from 'shared/components/forms';

import './distributor-data.scss';

export const DistributorData = () => {
  const css = useCSS('distributor-data');
  const { watch } = useFormContext<IDistributorForm>();

  const distributorData = watch('distributorData');
  const externalZoneInfo = `${distributorData.zoneCode} - ${distributorData.zoneName}`;

  return (
    <DistributorSection subtitle="1. Datos de operador">
      <div className={css()}>
        <InputValueForm label="Nombre del operador" value={distributorData.distributorName} isDisabled />
        <InputValueForm label="Oficina / Zona geográfica" value={externalZoneInfo} isDisabled />
        <InputValueForm label="Compañía" value={distributorData.distributorCode} isDisabled />
        <InputValueForm label="Sucursal" value={distributorData.branchCode} isDisabled />
      </div>
    </DistributorSection>
  );
};
