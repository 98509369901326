import { useFormContext } from 'react-hook-form';
import { Check } from '@alicorpdigital/dali-components-react';
import { FormGroup } from '@insuma/mpp-ui/components/form';
import { ESuggestion, ESuggestionLabel } from 'core/model/enums/suggested-product.enum';

import './suggestion-types.scss';

interface ISuggestionTypesProps {
  name: string;
}

export const SuggestionTypes = ({ name }: ISuggestionTypesProps) => {
  const { register } = useFormContext();
  return (
    <div className="suggestion-types">
      <FormGroup>
        <Check
          direction="vertical"
          id={`${name}-type-${ESuggestion.UNFORGIVABLES}`}
          type="radio"
          value={ESuggestion.UNFORGIVABLES}
          {...register(`${name}.type`)}
        >
          {ESuggestionLabel.UNFORGIVABLES}
        </Check>
        <Check
          direction="vertical"
          id={`${name}-type-${ESuggestion.ENHANCERS}`}
          type="radio"
          value={ESuggestion.ENHANCERS}
          {...register(`${name}.type`)}
        >
          {ESuggestionLabel.ENHANCERS}
        </Check>
        <Check
          direction="vertical"
          id={`${name}-type-${ESuggestion.IDEAL_SELECTION}`}
          type="radio"
          value={ESuggestion.IDEAL_SELECTION}
          {...register(`${name}.type`)}
        >
          {ESuggestionLabel.IDEAL_SELECTION}
        </Check>
      </FormGroup>
    </div>
  );
};
