import type { ICustomerDeliveryData } from 'core/model/interfaces/customers';
import { CustomerFormLayout } from 'screens/customers/customer-form/components';
import { InputValueForm } from 'shared/components/forms';

interface ICustomerDeliveryDataProps {
  deliveryData: ICustomerDeliveryData;
}
export const CustomerDeliveryData = ({ deliveryData }: ICustomerDeliveryDataProps) => (
  <CustomerFormLayout columns="two">
    <InputValueForm
      value={deliveryData.deliveryDate1}
      isDisabled
      label="Fecha de entrega 1"
      name="deliveryData.deliveryDate1"
    />
    <InputValueForm
      value={deliveryData.deliveryDate2}
      isDisabled
      label="Fecha de entrega 2"
      name="deliveryData.deliveryDate2"
    />
    <InputValueForm
      value={deliveryData.deliveryDate3}
      isDisabled
      label="Fecha de entrega 3"
      name="deliveryData.deliveryDate3"
    />
  </CustomerFormLayout>
);
