import { Check, Text } from '@alicorpdigital/dali-components-react';
import type { ICustomerBasicInfo } from 'core/model/interfaces/customers';
import {
  CustomerFormContainer,
  CustomerFormLayout,
  CustomerFormSubtitle,
  CustomerFormSwitchContainer,
} from 'screens/customers/customer-form/components';
import { InputValueForm } from 'shared/components/forms';

interface ICustomerBasicDataProps {
  title: string;
  basicInfo: ICustomerBasicInfo;
}
export const CustomerBasicData = ({ title, basicInfo }: ICustomerBasicDataProps) => (
  <CustomerFormContainer type="outlined">
    <CustomerFormLayout smallGaps>
      <CustomerFormSwitchContainer>
        <CustomerFormSubtitle standalone>{title}</CustomerFormSubtitle>
        <Check type="switch" checked={basicInfo.status} disabled>
          <Text as="span">{basicInfo.status ? 'Activo' : 'Inactivo'}</Text>
        </Check>
      </CustomerFormSwitchContainer>
      <CustomerFormLayout>
        <InputValueForm
          value={basicInfo.customerCode}
          isDisabled
          label="Código del cliente"
          name="basicInfo.customerCode"
        />
        <InputValueForm
          value={basicInfo.creationDate}
          isDisabled
          label="Fecha de creación clientes"
          name="basicInfo.creationDate"
        />
        <InputValueForm
          value={basicInfo.updateDate}
          isDisabled
          label="Fecha de modificación"
          name="basicInfo.updateDate"
        />
        <InputValueForm
          value={basicInfo.operatorName}
          isDisabled
          label="Nombre del operador"
          name="basicInfo.operatorName"
        />
        <InputValueForm
          value={basicInfo.companyCode}
          isDisabled
          label="Código de la compañía"
          name="basicInfo.companyCode"
        />
        <InputValueForm
          value={basicInfo.branchCode}
          isDisabled
          label="Código de sucursal"
          name="basicInfo.branchCode"
        />
        <InputValueForm value={basicInfo.subsegment} isDisabled label="Subsegmento" name="basicInfo.subsegment" />
        <InputValueForm value={basicInfo.b2bSegment} isDisabled label="Segmento B2B" name="basicInfo.b2bSegment" />
        <InputValueForm
          value={basicInfo.potencialSegment}
          isDisabled
          label="Segmento potencial"
          name="basicInfo.potencialSegment"
        />
        <InputValueForm
          value={basicInfo.pricesGroup}
          isDisabled
          label="Grupo de precios-pricing"
          name="basicInfo.pricesGroup"
        />
        <InputValueForm
          value={basicInfo.promotionalPricesGroup}
          isDisabled
          label="Grupo de precios promocional"
          name="basicInfo.promotionalPricesGroup"
        />
      </CustomerFormLayout>
    </CustomerFormLayout>
  </CustomerFormContainer>
);
