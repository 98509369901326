import { get, useFormContext } from 'react-hook-form';
import { FormGroup, Label } from '@insuma/mpp-ui/components/form';
import { HelperText } from '@insuma/mpp-ui/components/helper-text';
import { HIERARCHY_ASSOCIATION_TYPES } from 'core/constants/hierarchy.constants';
import { useMasterFamilies } from 'core/hooks/master-entities';
import { EHierarchyAssociation } from 'core/model/enums/hierarchy.enum';
import { INavigationTreeDetailCreateForm } from 'core/model/interfaces/navigation';
import { AutocompleteForm, DragAndDropImageUploaderForm, InputValueForm } from 'shared/components/forms';
import { TextareaForm } from 'shared/components/forms/textarea-form';
import { NavigationTreeFormRowContainer } from '../navigation-tree-form-row-container';
import { NavigationTreeFormSection } from '../navigation-tree-form-section';
import { NavigationTreeFormSubtitle } from '../navigation-tree-form-subtitle';

import './navigation-tree-form-category-fields.scss';

export const NavigationTreeFormCategoryFields = () => {
  const { familiesList, loading: loadingFamilies } = useMasterFamilies({ enabled: true });

  const {
    watch,
    formState: { errors },
    register,
  } = useFormContext<INavigationTreeDetailCreateForm>();
  const associationType = watch('associationType');

  return (
    <div className="navigation-tree-form-category-fields">
      <NavigationTreeFormRowContainer>
        <NavigationTreeFormSubtitle text="2. Estructura del árbol" />
        <NavigationTreeFormSection>
          <div>
            <InputValueForm
              label="Nombre de la categoría"
              name="categoryName"
              register={register}
              isInvalid={!!errors.categoryName}
              helperText={errors.categoryName?.message}
            />
            <InputValueForm
              label="Nombre de la subcategoría"
              name="subcategoryName"
              register={register}
              isInvalid={!!errors.subcategoryName}
              helperText={errors.subcategoryName?.message}
            />
            <FormGroup>
              <Label>Tipo de Asociación</Label>
              <AutocompleteForm
                source={HIERARCHY_ASSOCIATION_TYPES}
                name="associationType"
                getOptionValue={option => option.id}
                getOptionLabel={option => option.content}
                isInvalid={!!errors.associationType}
                isSearchable={false}
                isClearable={false}
                aria-label="Tipo de Asociación"
                helperText={errors.associationType?.message}
              />
            </FormGroup>
            {associationType === EHierarchyAssociation.BY_FAMILIES && (
              <FormGroup>
                <Label>Familias asociadas a la subcategoría</Label>
                <AutocompleteForm
                  source={familiesList}
                  name="families"
                  isLoading={loadingFamilies}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.description}
                  isMulti
                  isInvalid={!!errors.families}
                  placeholder="Selecciona las familias"
                  aria-label="Familias asociadas a la subcategoría"
                  helperText={errors.families?.message}
                />
              </FormGroup>
            )}
            {associationType === EHierarchyAssociation.BY_SKUS && (
              <TextareaForm
                label="SKUs asociados a la subcategoría"
                {...register('skus')}
                isInvalid={!!errors.skus}
                placeholder="Escribe los SKU"
                aria-label="SKUs asociados a la subcategoría"
                helperText={errors.skus?.message}
              />
            )}
          </div>
          <div>
            <FormGroup className="navigation-tree-form-category-fields__icon-section">
              <Label>Icono</Label>
              <DragAndDropImageUploaderForm name="categoryIcon" maxSize={500} />
              {errors.categoryIcon && <HelperText isInvalid>{get(errors, 'categoryIcon.blob.message')}</HelperText>}
            </FormGroup>
          </div>
        </NavigationTreeFormSection>
      </NavigationTreeFormRowContainer>
    </div>
  );
};
