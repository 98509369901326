import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { Form, FormControl, FormGroup, Label } from '@insuma/mpp-ui/components/form';
import { HelperText } from '@insuma/mpp-ui/components/helper-text';
import { defaultCustomization } from 'core/constants/customization.constants';
import { customizationRoutePath } from 'core/constants/routes.constants';
import { useDistributorsOptions, usePriceList, usePricesGroup, useZones } from 'core/hooks/master-entities';
import type { ICustomizationFormData } from 'core/model/interfaces/customization';
import { AutocompleteForm, InputValueForm } from 'shared/components/forms';
import { TextareaForm } from 'shared/components/forms/textarea-form';
import { Link } from 'shared/components/link';
import { schema } from './customization-form.schema';

import './customization-form.scss';

interface ICustomizationFormProps {
  onSubmit(data: ICustomizationFormData): void;
  formData?: ICustomizationFormData;
  isSaving?: boolean;
}

export const CustomizationForm = ({ isSaving = false, formData, onSubmit }: ICustomizationFormProps) => {
  const defaultValues: ICustomizationFormData = structuredClone(formData || defaultCustomization);

  const formMethods = useForm<ICustomizationFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const {
    handleSubmit,
    register,
    trigger,
    watch,
    setValue,
    formState: { errors },
  } = formMethods;

  const selectedDexPrices = watch('conditions.externalPriceGroups');

  const { pricesGroupList, loading: loadingPriceGroup } = usePricesGroup();
  const { priceList, loading: loadingPriceList } = usePriceList(selectedDexPrices);
  const { distributorsList, loading: loadingDistributors } = useDistributorsOptions();
  const { externalZoneList, loading: loadingZones } = useZones();

  return (
    <div className="customization-form">
      <Link icon="caret-left" to={customizationRoutePath}>
        Atrás
      </Link>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2 className="customization-form__title">{formData ? 'Editar' : 'Nueva'} personalización</h2>
        <FormProvider {...formMethods}>
          <div className="customization-form__container">
            <div className="row-container">
              <h4 className="customization-form__subtitle">1. Datos de la personalización</h4>
              <div className="customization-form__section">
                <InputValueForm
                  label="Nombre de la personalización"
                  register={register}
                  name="name"
                  placeholder="Ejemplo: Para apudex"
                  helperText={errors.name?.message}
                  isInvalid={!!errors.name}
                />
              </div>
            </div>

            <div className="row-container">
              <h4 className="customization-form__subtitle">2. Estructura de la personalización</h4>
              <p>Segmento de usuarios que podrán visualizar la página</p>
              <FormGroup>
                <Label>Por grupo de precios DEX</Label>
                <AutocompleteForm
                  source={pricesGroupList}
                  name="conditions.externalPriceGroups"
                  isLoading={loadingPriceGroup}
                  getOptionValue={option => option.priceGroupCode}
                  getOptionLabel={option => option.priceGroupDescription}
                  onChangeProp={() => {
                    setValue('conditions.externalPriceLists', []);
                    trigger('conditions');
                  }}
                  isMulti
                />
              </FormGroup>
              <FormGroup>
                <Label>Por lista de precios DEX</Label>
                <AutocompleteForm
                  source={priceList}
                  name="conditions.externalPriceLists"
                  isLoading={loadingPriceList}
                  getOptionValue={option => option.priceListCode}
                  getOptionLabel={option => option.priceListDescription}
                  onChangeProp={() => trigger('conditions')}
                  isMulti
                />
              </FormGroup>
              <FormGroup>
                <Label>Por operador</Label>
                <AutocompleteForm
                  source={distributorsList}
                  name="conditions.externalDistributors"
                  isLoading={loadingDistributors}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  onChangeProp={() => trigger('conditions')}
                  isMulti
                />
              </FormGroup>
              <FormGroup>
                <Label>Por oficina</Label>
                <AutocompleteForm
                  source={externalZoneList}
                  name="conditions.externalZones"
                  isLoading={loadingZones}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.description}
                  onChangeProp={() => trigger('conditions')}
                  isMulti
                />
              </FormGroup>
              <TextareaForm
                label="Por cliente"
                {...register('conditions.externalCustomers', {
                  onChange: () => trigger('conditions'),
                })}
              />
              <FormControl>
                {errors.conditions && <HelperText isInvalid>{errors.conditions.message}</HelperText>}
              </FormControl>
            </div>

            <div className="row-container">
              <h4 className="customization-form__subtitle">3. Excluir página de inicio</h4>
              <TextareaForm
                label="Segmento de usuarios que no podrán visualizar la página"
                {...register('externalExcludedCustomers')}
                placeholder="Ingresar clientes"
              />
            </div>
          </div>
        </FormProvider>
        <div className="customization-form__actions">
          <Button type="submit" isLoading={isSaving} width="15rem">
            Guardar
          </Button>
        </div>
      </Form>
    </div>
  );
};
