import React from 'react';
import { FormGroup, ITextareaProps, Label, Textarea } from '@insuma/mpp-ui/components/form';

interface ITextareaFormProps extends ITextareaProps {
  label?: string;
  className?: string;
}

export const TextareaForm = React.forwardRef<HTMLTextAreaElement, ITextareaFormProps>(
  ({ label, className, children, ...rest }, ref) => (
    <FormGroup className={className} controlId={rest.name}>
      {label && <Label>{label}</Label>}
      <Textarea aria-label={label} {...rest} ref={ref} />
      {children}
    </FormGroup>
  ),
);
