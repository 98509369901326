import { FieldValues, Path, UseFormRegister } from 'react-hook-form';
import { FormGroup, IInputProps, Input, Label } from '@insuma/mpp-ui/components/form';

interface IInputValueFormProps<T extends FieldValues> extends IInputProps {
  label?: string;
  className?: string;
  name?: Path<T>;
  register?: UseFormRegister<T>;
}

export const InputValueForm = <T extends FieldValues>({
  label,
  register,
  className,
  onChange,
  name,
  children,
  ...rest
}: IInputValueFormProps<T>) => {
  const registerProps =
    register && name
      ? register(name, { onChange })
      : {
          name,
          onChange,
        };

  return (
    <FormGroup className={className}>
      {label && <Label>{label}</Label>}
      <Input aria-label={label} {...rest} {...registerProps} />
      {children}
    </FormGroup>
  );
};
