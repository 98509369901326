import { Button } from '@insuma/mpp-ui/components/button';
import type { IColumnProps } from '@insuma/mpp-ui/components/table';
import type { IDisplayProduct, IUnitMeasure } from 'core/model/interfaces/products';

export const getProductsColumns = (onView: (sku: string) => void): Array<IColumnProps<IDisplayProduct>> => [
  {
    header: 'Sku',
    accessor: 'sku',
  },
  {
    header: 'Nombre',
    accessor: 'name',
  },
  {
    header: '',
    accessor: 'events',
    render: row => (
      <div className="customization-list__table-actions">
        <Button isIconOnly size="sm" iconName="eye" variant="tertiary" onClick={() => onView(row.sku)} />
      </div>
    ),
  },
];

export const getPropertiesFromUnitMeasure = (
  unitMeasure: Array<IUnitMeasure>,
  isMain: boolean,
): { description: string; conversionFactor: string; presentation: string } => {
  const uM = unitMeasure.find(um => um.default === isMain);
  return {
    description: uM?.description ?? '',
    conversionFactor: uM?.conversionFactorSale?.toString() ?? '',
    presentation: uM?.presentation ?? '',
  };
};
