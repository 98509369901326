import { Heading } from '@alicorpdigital/dali-components-react';
import { Form } from '@insuma/mpp-ui/components/form';
import { Tabs } from '@insuma/mpp-ui/components/tabs';
import type { ICustomerForm } from 'core/model/interfaces/customers';
import { Link } from 'shared/components/link';
import { ECustomerFormTabs } from './customer-form.types';
import { CustomerDeliveryAndVisits, CustomerMainInformation, CustomerPaymentAndTaxes } from './tabs';

import './customer-form.scss';

interface ICustomerFormProps {
  customer: ICustomerForm;
}

export const CustomerForm = ({
  customer: {
    apudex: { basicInfo: apudexBasicInfo, payment: apudexPayment },
    dex: { basicInfo: dexBasicInfo, payment: dexPayment },
    commercialData,
    deliveryAddresses,
    deliveryData,
    taxData,
    visitData,
  },
}: ICustomerFormProps) => (
  <Form>
    <div className="customer-form">
      <div className="customer-form__back-link">
        <Link to="/clientes" icon="caret-left">
          Atrás
        </Link>
      </div>
      <div className="customer-form__header">
        <Heading size="sm">Ver Cliente</Heading>
      </div>
      <div className="customer-form__body">
        <Tabs defaultTab={ECustomerFormTabs.MAIN}>
          <Tabs.List>
            <Tabs.Tab value={ECustomerFormTabs.MAIN} title="Información principal" />
            <Tabs.Tab value={ECustomerFormTabs.TAXES_AND_CREDITS} title="Impuestos y créditos" />
            <Tabs.Tab value={ECustomerFormTabs.DELIVERY_AND_VISITS} title="Entrega y visita" />
          </Tabs.List>
          <Tabs.Panel value={ECustomerFormTabs.MAIN}>
            <CustomerMainInformation
              apudexBasicInfo={apudexBasicInfo}
              commercialData={commercialData}
              dexBasicInfo={dexBasicInfo}
            />
          </Tabs.Panel>
          <Tabs.Panel value={ECustomerFormTabs.TAXES_AND_CREDITS}>
            <CustomerPaymentAndTaxes apudexPayment={apudexPayment} dexPayment={dexPayment} taxes={taxData} />
          </Tabs.Panel>
          <Tabs.Panel value={ECustomerFormTabs.DELIVERY_AND_VISITS}>
            <CustomerDeliveryAndVisits
              deliveryAddresses={deliveryAddresses}
              deliveryData={deliveryData}
              visitData={visitData}
            />
          </Tabs.Panel>
        </Tabs>
      </div>
    </div>
  </Form>
);
